import Meta from '../components/Meta'
import SlideShow from '../components/SlideShow'
const Home = () => {
  // page content
  const pageTitle = 'wqszhr'

  return (
    <>
          <Meta title={pageTitle} />
          <div className="testing">
              <SlideShow />
          </div>
    </>
  )
}

export default Home