import Carousel from 'react-bootstrap/Carousel'
import { Container } from 'react-bootstrap';

const SlideShow = () => {

    return (
        <>
            <Container fluid>
                <Carousel fade>
                    <Carousel.Item>
                        <img src="/img/file.png" alt="tower" style={{ maxWidth: "100%"}} />
                        <Carousel.Caption>
                            <h3>First slide label</h3>
                            <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img src="/img/DSC_0151.jpg" alt="bridge" style={{ maxWidth: "100%" }} />
                        <Carousel.Caption>
                            <h3>Second slide label</h3>
                            <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img src="/img/DSC_0008.jpg" alt="bridge" style={{ maxWidth: "100%" }} />
                        <Carousel.Caption>
                            <h3>Third slide label</h3>
                            <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                        </Carousel.Caption>
                    </Carousel.Item>
                </Carousel>
            </Container>
            </>
        )
};

export default SlideShow;

